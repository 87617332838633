export const LOGIN_SUCCESS = 'Login successful'
export const LOGIN_SUCCESS_MESSAGE = 'You have successfully logged in!'
export const SESSION_EXPIRED_MESSAGE = 'Session expired'
export const SESSION_EXPIRED_MESSAGE_DESC = 'Your session expired. Please sign in again'
export const LOGIN_FAIL_MESSAGE = 'Please check your username & password and try again'
export const SUCCESS = 'Success!'
export const EDIT_SUCCESS_MESSAGE = 'Successfully edited'
export const ADD_SUCCESS_MESSAGE = 'Successfully added'
export const DELETE_SUCCESS_MESSAGE = 'Deleted successfully'
export const FAILED = 'FAILED'
export const ERROR_TRY_LATER = "'Something went wrong. Please try again later!'"

export const ROLES = {
  admin: 'admin',
  seller: 'seller',
  hospital: 'hospital',
  enduser: 'enduser',
}

export const STRINGS = {
  success: 'Success!',
  editSuccess: 'Edited successfully!',
  Verified: 'Verified successfully!',
  editError: 'Error editing!',
  addError: 'Error adding!',
  addSuccess: 'Added successfully!',
  deleteSuccess: 'Deleted successfully!',
  deleteError: 'Error deleting!',
  loginFailed: 'Login failed!',
  error: 'Error!',
  formsErrors: 'Some forms contain errors! Please re-check before submitting again',
}

export const LINKS = {
  productList: '/catalog/products',
  editProduct: '/catalog/products/product',
  addProduct: '/catalog/products/add-product',
}

export const CATALOG_API_URL = {
  getAllUser: '/api/backend/v1/users/all',
  users: '/api/backend/v1/users',
  signup: '/api/backend/v1/users/register',
  getMailSettings: '/api/backend/v1/mailSettings',
  getGeneralSettings: '/api/catalog/v1/generalSettings',
  getFooterSettings: '/api/catalog/v1/footerlinks',
  uploadExcel: '/api/catalog/v1/uploadExcel',
  uploadImages: '/api/catalog/v1/uploadImages',

  verifyUser: '/api/backend/v1/users/verify',
  updateUserPassword: '/api/backend/v1/users/updatePasswordbyadmin',

  widget: '/api/catalog/v1/widget',
  widgetCreate: '/api/catalog/v1/widget/create',
  widgetTab: '/api/catalog/v1/widgetTab',
  widgetTabCreate: '/api/catalog/v1/widgetTab/create',
}

export const USERGROUP_API_URL = {
  getUserGroup: '/api/backend/v1/userGroup',
}

export const BANNER_API_URL = {
  getBanners: '/api/catalog/v1/banner',
}
export const CATALOG_BANNER_API_URL = {
  getBanners: '/api/catalog/v1/widgetbanner',
}

export const USER_API_URL = {
  getUserPerPage: '/api/backend/v1/usersperpage',
}



// /////////////////////////////
export const organizationContentKeys = [
  'ORGANISATION_LOGO',
  'ORGANISATION_NAME',
  'ORGANISATION_PHONE',
  'ORGANISATION_ADDRESS'
];

export const patientContentKeys = [
  'PATIENT_FIRSTNAME',
  'PATIENT_LASTNAME',
  'PATIENT_FULLNAME'
];

export const appointmentContentKeys = [
  'APPOINTMENT_DATE',
  'LOCATION_NAME',
  'LOCATION_ADDRESS',
  'LOCATION_PHONE',
  'PROVIDER_NAME',
];

export const paymentContentKeys = ['PAYMENT_AMOUNT', 'PAYMENT_REASON', 'PAYMENT_LINK'];

// export const appointmentTemplateTypes = [
//   'Reminder',
//   'Recall',
//   'Review',
//   'Appointment Request',
//   'Appointment Confirmed',
//   'Appointment Rescheduled',
//   'Appointment Missed',
//   'Appointment Cancelled',
//   'Appointment Completed',
// ];

// export const paymentTemplateTypes = [
//   'Payment Request',
//   'Payment Success',
//   'Payment Reminder',
//   'Payment Cancelled',
//   'Payment Failed',
// ];
