import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import AppointmentType from './appointment_type/reducers'
import Branch from './branch/reducers';
import Provider from './provider/reducers';
import Schedule from './schedule/reducers';
// import attributes from './attributes/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    AppointmentType,
    Branch,
    Provider,
    Schedule
    // attributes
  })
